import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { getProducts, getItems } from "../services/collectionServices"
import { getCurrentUser } from "../services/authService"
import UserAreaMenu from "../components/userAreaMenu"
import { anonymousUser, emptyStore } from "../config.json"
import ReactSelect from "react-select"
import { animateScroll as scroll } from "react-scroll"
import {
  getLocalCart,
  setLocalCart,
  transformApiProductsInGroupedOptions,
  transformArrayToOptions,
  apiStore2SelectStores,
  sIsNumber,
} from "../utils/functions"
import SpinnerLoading from "../components/common/spinnerLoading"
import Pagination from "../components/common/pagination"
import { toast } from "react-toastify"
import {
  FaLevelUpAlt,
  FaGenderless,
  FaGlasses,
  FaTint,
  FaRegClock,
} from "react-icons/fa"

const myScrollToAnchor = function(anchor) {
  scroll.scrollTo(950)
}

const drawStoreCard = function(storeSelected) {
  let s = ""
  if (
    storeSelected &&
    storeSelected.obj &&
    storeSelected.obj.relationships &&
    storeSelected.id > 0
  ) {
    const address = storeSelected.obj.relationships.address.data.attributes
    const notes = storeSelected.obj.attributes.notes ? storeSelected.obj.attributes.notes : '--'

    return (
      <div
        style={{
          backgroundColor: "#fafafa",
          color: "#666",
          padding: "20px",
        }}
      >
        <div className={"row"} style={{ marginBottom: "1rem" }}>
          <div className={"col-md-6"}>
            <h3 style={{ fontSize: "1.2rem" }}>Dati anagrafici</h3>
            <div style={{ fontWeight: "bold" }}>{storeSelected.value}</div>
            <div style={{ fontSize: "0.8rem" }}>
              {address.address_line}<br />{address.postal_code} {address.city} ({address.state}) {address.country}
            </div>
            {storeSelected.obj.attributes.email && (
              <div style={{ fontSize: "0.8rem" }}>Email: <a href={"mailto:"+storeSelected.obj.attributes.email}>{storeSelected.obj.attributes.email}</a></div>
            )}
            {address.phone_number && (
              <div style={{ fontSize: "0.8rem" }}>Telefono: {address.phone_number}</div>
            )}
          </div>
          <div className={"col-md-6"}>
            <h3 style={{ fontSize: "1.2rem" }}>Dati amministrativi</h3>
            <div style={{ fontSize: "0.8rem" }}>Codice cliente: {storeSelected.obj.attributes.code}</div>
            {storeSelected.obj.attributes.vat_number && (
              <div style={{ fontSize: "0.8rem" }}>Partita IVA: {storeSelected.obj.attributes.vat_number}</div>
            )}
            {storeSelected.obj.attributes.payment_description && (
              <div style={{ fontSize: "0.8rem" }}>Modalità di pagamento: {storeSelected.obj.attributes.payment_description}</div>
            )}
            {storeSelected.obj.attributes.agent_code && (
              <div style={{ fontSize: "0.8rem" }}>Agente: {storeSelected.obj.attributes.agent_code} {storeSelected.obj.attributes.agent_name}</div>
            )}
            {storeSelected.obj.attributes.price_list_code && (
              <div style={{ fontSize: "0.8rem" }}>Listino: {storeSelected.obj.attributes.price_list_code}</div>
            )}
          </div>
        </div>

        <div className={"row"}>
          <div className={"col"}>
            <h3 style={{ fontSize: "1.2rem" }}>Note</h3>
            <div style={{ fontSize: "0.8rem" }}>{notes}</div>
          </div>
        </div>

      </div>
    )
  } else {
    s = ""
  }
  return s
}

const drawStoreLabel = function(storeSelected) {
  if (
    storeSelected &&
    storeSelected.obj &&
    storeSelected.obj.relationships &&
    storeSelected.id > 0
  ) {
    const address = storeSelected.obj.relationships.address.data.attributes
    return (
      <>
        {storeSelected.value} [{storeSelected.obj.attributes.code}]-{" "}
        <span
          style={{ fontSize: "0.8rem", fontStyle: "italic", color: "#b1b1b1" }}
        >
          {address.address_line} {address.postal_code} {address.city} (
          {address.state}) {address.country}
        </span>
      </>
    )
  } else {
    return ""
  }
}

class Ordine extends Component {
  state = {
    stores: [],
    paginationData: {
      current_page: 0,
      from: 0,
      last_page: 0,
      path: "",
      per_page: 0,
      to: 0,
      total: 0,
    },
    paginationProducts: {
      current_page: 0,
      from: 0,
      last_page: 0,
      path: "",
      per_page: 0,
      to: 0,
      total: 0,
    },
    defaultPaginationPerPage: 100,
    products: [{ id: 0, attributes: { code: 0, description: "", price: 10 } }],
    productsFiltered: [],
    productFilteredSelected: 0,
    cart: { products: [] },
    refreshSideCart: false,
    user: { ...anonymousUser },
    userIsClient: false,
    productsLoading: false,
    storesLoading: true,
    storeSelected: { ...emptyStore },
    showToricSelects: false,
    groupedOptions: [],
    axes: [],
    cylinders: [],
    spheres: [],
    metaFilter: { axes: "", cylinders: "", spheres: "" },
    selectedOption: {},
    storeSearchText: "",
    cartUploading: false,
    groups: [],
    groupsToShow: [],
    groupsParentArray: [],
    productModelsToShow: [],
    productSearch: "",
    searchMode: "",
    timeout: null,
  }

  async componentDidMount() {
    // Recupero l'utente loggato
    // Legenda utente
    // user.relationships.roles.data[0].attributes.name (Client, Agent, Area Manager) // user.relationships.roles.data[0].id (1=Super Admin, 2=Agent, 3=Client, 4=Area Manager)
    const user = await getCurrentUser()
    let userIsClient = true
    if (user.id === 0) {
      window.location = "/login"
      return
    }
    try {
      userIsClient = user.relationships.roles.data[0].id === 3
    } catch (error) {}

    this.setState({
      user,
      userIsClient,
    })

    // Recupero il carrello
    let cart = getLocalCart()

    // Recupero gli store
    let storeSearchText = ""
    let storeSelected = { ...emptyStore }
    let stores = []
    if (userIsClient) {
      stores = await getItems("stores")
      stores = apiStore2SelectStores(stores.data)
      if (stores.length === 1) {
        storeSelected = { ...stores[0] }
        storeSearchText = storeSelected.value
      }
    }
    this.setState({ storesLoading: false })

    if (cart.store.id > 0) {
      storeSelected = cart.store
      storeSearchText = storeSelected.fullName
      //this.selectStoreByAgent(cart.store)
    } else {
      cart.store = storeSelected
      setLocalCart(cart)
    }

    let groupedOptions = []
    let axes = []
    let cylinders = []
    let spheres = []
    let metaFilter = { axes: "", cylinders: "", spheres: "" }

    // Recupero le categorie dei prodotti
    const groups = await getItems("products/groups")
    groupedOptions = transformApiProductsInGroupedOptions(groups)

    // Recupero Sphere, Cylinder, Axis
    const toricData = await getItems("products/model/toric/meta")
    try {
      axes = transformArrayToOptions(toricData.meta.axes, "axes")
      cylinders = transformArrayToOptions(toricData.meta.cylinders, "cylinders")
      spheres = transformArrayToOptions(toricData.meta.spheres, "spheres")
      metaFilter.axes = axes[0]
      metaFilter.spheres = spheres[0]
      metaFilter.cylinders = cylinders[0]
    } catch (error) {}

    this.setState({
      stores,
      cart,
      groupedOptions,
      axes,
      cylinders,
      spheres,
      metaFilter,
      storeSelected,
      storeSearchText,
      groups: [...groups.data],
      groupsToShow: [...groups.data],
    })
  }

  handlePlusMinusClick = (e, product, target, step) => {
    e.preventDefault()
    const { productsFiltered } = this.state
    const { refreshSideCart } = this.state
    let productFilteredSelected = 0
    let newProducts = [...productsFiltered]
    for (let i = 0; i < newProducts.length; i++) {
      if (newProducts[i].id === product.id) {
        if (!sIsNumber(newProducts[i][target])) {
          newProducts[i][target] = 0
        }
        newProducts[i][target] =
          parseInt(newProducts[i][target]) + parseInt(step)
        if (newProducts[i][target] < 0) {
          newProducts[i][target] = 0
        }
        if (
          !sIsNumber(newProducts[i][target]) ||
          newProducts[i][target] === 0
        ) {
          newProducts[i][target] = ""
        }
      }
      if (sIsNumber(newProducts[i].quantity)) {
        productFilteredSelected += parseInt(newProducts[i].quantity)
      }
      if (sIsNumber(newProducts[i].discountedQuantity)) {
        productFilteredSelected += parseInt(newProducts[i].discountedQuantity)
      }
    }
    this.setState({
      cart: {
        productsFiltered: newProducts,
        refreshSideCart: !refreshSideCart,
      },
      productFilteredSelected,
    })
  }

  changeQuantity = (product, target, quantity) => {
    //if (quantity === '') { return; }
    let quantityInt = 0
    if (quantityInt !== "") {
      quantityInt = quantity
    }
    const { productsFiltered } = this.state
    const { refreshSideCart } = this.state
    let productFilteredSelected = 0
    let newProducts = [...productsFiltered]
    for (let i = 0; i < newProducts.length; i++) {
      if (newProducts[i].id === product.id) {
        if (!sIsNumber(newProducts[i][target])) {
          newProducts[i][target] = 0
        }
        newProducts[i][target] = parseInt(quantityInt)
        if (newProducts[i][target] < 0) {
          newProducts[i][target] = 0
        }
        if (
          !sIsNumber(newProducts[i][target]) ||
          newProducts[i][target] === 0
        ) {
          newProducts[i][target] = ""
        }
      }
      if (sIsNumber(newProducts[i].quantity)) {
        productFilteredSelected += parseInt(newProducts[i].quantity)
      }
      if (sIsNumber(newProducts[i].discountedQuantity)) {
        productFilteredSelected += parseInt(newProducts[i].discountedQuantity)
      }
    }
    this.setState({
      cart: {
        productsFiltered: newProducts,
        refreshSideCart: !refreshSideCart,
      },
      productFilteredSelected,
    })
  }

  handleInputChange = (obj, { currentTarget }) => {
    this.changeQuantity(obj, currentTarget.id, currentTarget.value)
  }

  productsFilteredAddQuantities(productsFiltered) {
    let products = [...productsFiltered]
    for (let i = 0; i < products.length; i++) {
      products[i].quantity = ""
      products[i].discountedQuantity = ""
    }
    return products
  }

  searchProductByText = async query => {
    if (query.trim().length < 3) {
      return
    }

    this.setState({ productsLoading: true })
    let products = await getProducts("products/search", query)
    let productsFiltered = [...products.data]
    let paginationProducts = { ...products.meta }
    productsFiltered = this.productsFilteredAddQuantities(productsFiltered)

    myScrollToAnchor("productsFilteredAnchor")

    this.setState({
      products,
      productsFiltered,
      paginationProducts,
      productsLoading: false,
    })
    this.resetGroupsButtons()
  }

  handleProductSearchChange = async e => {
    const { groups } = this.state
    this.setState({
      groupsToShow: [...groups],
      productModelsToShow: [],
      productSearch: e.target.value,
      searchMode: "manual",
    })
    const query = e.target.value

    clearTimeout(this.state.timeout)
    this.setState({
      timeout: setTimeout(
        function() {
          this.searchProductByText(query)
        }.bind(this),
        500
      ),
    })
  }

  handleAddSelectedProductsToCart = async e => {
    e.preventDefault()

    // Se sto già aggiornando il carrello esco subito
    if (this.state.cartUploading) {
      return
    }

    // Avviso tutti che sto aggiornando il carrello
    this.setState({ cartUploading: true }, async () => {
      // Raccolgo il carrello dalla local storage
      let cart = getLocalCart()
      let flagProductExistOnCart = false
      const { productsFiltered } = this.state
      let newProductsOnCartIds = []

      // Controllo per ogni prodotto selezionato se esiste già nel carrello
      for (let i = 0; i < productsFiltered.length; i++) {
        flagProductExistOnCart = false

        for (let j = 0; j < cart.products.length; j++) {
          // Se il prodotto esiste nel carrello aggiungo le quantità
          if (productsFiltered[i].quantity > 0) {
            if (cart.products[j].id === productsFiltered[i].id) {
              if (!sIsNumber(cart.products[j].quantity)) {
                cart.products[j].quantity = 0
              }
              cart.products[j].quantity =
                parseInt(cart.products[j].quantity) +
                parseInt(productsFiltered[i].quantity)
              flagProductExistOnCart = true
            }
          }

          if (productsFiltered[i].discountedQuantity > 0) {
            if (cart.products[j].id === productsFiltered[i].id) {
              if (!sIsNumber(cart.products[j].discountedQuantity)) {
                cart.products[j].discountedQuantity = 0
              }
              cart.products[j].discountedQuantity =
                parseInt(cart.products[j].discountedQuantity) +
                parseInt(productsFiltered[i].discountedQuantity)
              flagProductExistOnCart = true
            }
          }
        } // for j

        // Se il prodotto non è nel carrello lo aggiungo
        let productToAdd = { ...productsFiltered[i] }
        if (
          !flagProductExistOnCart &&
          (productsFiltered[i].quantity > 0 ||
            productsFiltered[i].discountedQuantity > 0)
        ) {
          newProductsOnCartIds.push(productToAdd.id)
          cart.products.push({ ...productToAdd })
        }

        // Azzero le quantità selezionate perchè ora sono nel carrello
        productsFiltered[i].quantity = 0
        productsFiltered[i].discountedQuantity = 0
      } // for i

      /*if (newProductsOnCartIds.length === 0) {
              this.setState({ cartUploading: false })
              return ;
          }*/

      // Se sto inserendo nuovi articoli nel cart chiedo al server i prezzi da integrare
      if (newProductsOnCartIds.length > 0) {
        const { storeSelected } = this.state
        const prices = await getItems(
          "products/store/" +
            storeSelected.id +
            "/prices?products=" +
            newProductsOnCartIds.join(",")
        )
        for (let i = 0; i < cart.products.length; i++) {
          let product = cart.products[i]
          for (let price_index in prices.data) {
            if (product.id === parseInt(price_index)) {
              cart.products[i].price = prices.data[price_index]
            }
          }
        }
      }

      setLocalCart(cart)
      let productFilteredSelected = 0

      // Modifica del 07/05/2019
      //this.setState({productsFiltered, productFilteredSelected, cartUploading: false })
      const { productModelsToShow } = this.state
      for (let i = 0; i < productModelsToShow.length; i++) {
        productModelsToShow[i].selected = false
      }
      this.setState({
        productModelsToShow,
        productsFiltered: [],
        productFilteredSelected,
        cartUploading: false,
      })

      //this.resetGroupsButtons()
      toast.success("Prodotti aggiunti al carrello con successo")
    })
  }

  createMetaQueryString() {
    const { metaFilter } = this.state
    let queryString = ""
    try {
      if (metaFilter.axes.value !== "") {
        queryString += "axis=" + metaFilter.axes.value
      }
      if (metaFilter.cylinders.value !== "") {
        if (queryString !== "") {
          queryString = queryString + "&"
        }
        queryString += "cylinder=" + metaFilter.cylinders.value
      }
      if (metaFilter.spheres.value !== "") {
        if (queryString !== "") {
          queryString = queryString + "&"
        }
        queryString += "sphere=" + metaFilter.spheres.value
      }
      if (queryString !== "") {
        queryString = "?" + queryString
      }
      queryString = queryString.replace("+", "%2B")
    } catch (error) {}
    return queryString
  }

  handleClientProductsSelectChange = async selectedOption => {
    const { groups } = this.state
    this.setState({
      productsLoading: true,
      selectedOption,
      groupsToShow: [...groups],
      productModelsToShow: [],
    })

    let queryString = ""
    if (selectedOption.is_toric) {
      queryString = this.createMetaQueryString()
    }

    queryString +=
      queryString === ""
        ? "?per_page=" + this.state.defaultPaginationPerPage
        : "&per_page=" + this.state.defaultPaginationPerPage

    const products = await getItems(
      "products/get-by-model/" + selectedOption.id + queryString
    )
    let productsFiltered = [...products.data]
    let paginationProducts = { ...products.meta }
    productsFiltered = this.productsFilteredAddQuantities(productsFiltered)

    myScrollToAnchor("productsFilteredAnchor")

    this.setState({
      products,
      productsFiltered,
      paginationProducts,
      productsLoading: false,
      showToricSelects: selectedOption.is_toric,
    })
  }

  handleMetaChange = async metaSelectedOption => {
    this.setState({ productsLoading: true })
    const { metaFilter, selectedOption, showToricSelects } = this.state
    metaFilter[metaSelectedOption.type] = metaSelectedOption
    this.setState({ metaFilter })

    let queryString = ""
    if (showToricSelects) {
      queryString = this.createMetaQueryString()
    }

    queryString +=
      queryString === ""
        ? "?per_page=" + this.state.defaultPaginationPerPage
        : "&per_page=" + this.state.defaultPaginationPerPage

    const products = await getItems(
      "products/get-by-model/" + selectedOption.id + queryString
    )
    let productsFiltered = [...products.data]
    let paginationProducts = { ...products.meta }
    productsFiltered = this.productsFilteredAddQuantities(productsFiltered)

    this.setState({
      products,
      productsFiltered,
      paginationProducts,
      productsLoading: false,
    })
  }

  handleClientStoreSearchChange = store => {
    let cart = getLocalCart()
    cart.store = store
    setLocalCart(cart)
    this.setState({ storeSelected: store, storeSearchText: store.value })
  }

  searchStoresByText = async (query, page) => {
    if (page === undefined) {
      page = 1
    }
    if (query.trim().length < 3) {
      return
    }
    this.setState({ storesLoading: true, storeSelected: { ...emptyStore } })
    let stores = await getItems(
      "stores/search?filter[query]=" + query + "&page=" + page
    )
    let paginationData = JSON.parse(JSON.stringify(stores.meta))
    stores = apiStore2SelectStores(stores.data)
    this.setState({ storesLoading: false, stores, paginationData })
  }

  handleStoreSearchChange = e => {
    const query = e.target.value
    this.setState({ storeSearchText: query })

    const cojone = this.state

    clearTimeout(this.state.timeout)
    this.setState({
      timeout: setTimeout(
        function() {
          this.searchStoresByText(query)
        }.bind(this),
        3000
      ),
    })
  }

  selectStoreByAgent = store => {
    let cart = getLocalCart()
    //cart.store = apiStore2SelectStore(store)
    cart.store = store
    setLocalCart(cart)
    this.setState({ storeSelected: store, storeSearchText: store.value })
  }

  handleSelectStoreByAgent = (e, store) => {
    e.preventDefault()
    this.selectStoreByAgent(store)
  }

  handleGroupClick = async (e, group) => {
    e.preventDefault()
    this.setState({
      productSearch: "",
      products: [],
      productsFiltered: [],
      productsLoading: false,
      searchMode: "buttons",
    })
    if (typeof group.relationships === "undefined") {
      this.setState({ productsLoading: true })

      let queryString = ""
      if (group.meta.is_toric) {
        queryString = this.createMetaQueryString()
      }

      queryString +=
        queryString === ""
          ? "?per_page=" + this.state.defaultPaginationPerPage
          : "&per_page=" + this.state.defaultPaginationPerPage

      const { productModelsToShow } = this.state
      for (let i = 0; i < productModelsToShow.length; i++) {
        if (productModelsToShow[i].id === group.id) {
          productModelsToShow[i].selected = true
        } else {
          productModelsToShow[i].selected = false
        }
      }

      const products = await getItems(
        "products/get-by-model/" + group.id + queryString
      )
      let productsFiltered = [...products.data]
      let paginationProducts = { ...products.meta }
      productsFiltered = this.productsFilteredAddQuantities(productsFiltered)

      let scroll = true
      if (products.data && products.data.length > 0) {
        if (products.data[0].meta.is_toric === true) {
          scroll = false
        }
      }
      if (scroll) {
        myScrollToAnchor("productsFilteredAnchor")
      }

      this.setState({
        products,
        productsFiltered,
        paginationProducts,
        productsLoading: false,
        selectedOption: group,
        showToricSelects: group.meta.is_toric,
      })
    } else {
      const { groupsToShow, groupsParentArray } = this.state
      groupsParentArray.push([...groupsToShow])
      this.setState({ groupsParentArray })
      if (group.relationships.children.data.length > 0) {
        this.setState({
          productModelsToShow: [],
          groupsToShow: [...group.relationships.children.data],
        })
      } else if (group.relationships.product_models.data.length > 0) {
        this.setState({
          groupsToShow: [],
          productModelsToShow: [...group.relationships.product_models.data],
        })
      } else {
        // Nessun prodotto trovato
        this.setState({ groupsToShow: [], productModelsToShow: [] })
      }
    }
  }

  handleGroupLevelUp = e => {
    e.preventDefault()
    const { groupsParentArray } = this.state
    const groupsToShow = groupsParentArray.pop()
    this.setState({ groupsToShow, groupsParentArray, productModelsToShow: [] })
  }

  static stringToCssClass(string) {
    return string
      .replace(/ /g, "-")
      .trim()
      .toLowerCase()
  }

  static provideProductGroupIcon(cssClass) {
    switch (cssClass) {
      case "lenti-a-contatto":
        return <FaGenderless />
      case "soluzioni":
        return <FaTint />
      case "accessori":
        return <FaGlasses />
      case "lenti-a-contatto-mensili":
      case "lenti-a-contatto-quindicinali":
      case "lenti-a-contatto-giornaliere":
        return <FaRegClock />
      default:
        break
    }
  }

  resetGroupsButtons = () => {
    const { groups } = this.state
    this.setState({
      groupsToShow: [...groups],
      groupsParentArray: [],
      productModelsToShow: [],
    })
  }

  handleOnStoresPageChange = page => {
    this.searchStoresByText(this.state.storeSearchText, page)
  }

  handleOnProductPageChange = async page => {
    this.setState({ productsLoading: true })
    const { selectedOption } = this.state
    const products = await getItems(
      "products/get-by-model/" + selectedOption.id + "/?page=" + page
    )
    let productsFiltered = [...products.data]
    let paginationProducts = { ...products.meta }
    productsFiltered = this.productsFilteredAddQuantities(productsFiltered)

    this.setState({
      products,
      productsFiltered,
      paginationProducts,
      productsLoading: false,
    })
  }

  render() {
    const {
      stores,
      productsFiltered,
      productFilteredSelected,
      refreshSideCart,
      productsLoading,
      storesLoading,
      user,
      userIsClient,
      showToricSelects,
      axes,
      cylinders,
      spheres,
      metaFilter,
      storeSelected,
      storeSearchText,
      cartUploading,
      groupsToShow,
      productModelsToShow,
      groupsParentArray,
      productSearch,
      searchMode,
      paginationData,
      paginationProducts,
    } = this.state

    return (
      <Layout>
        <SEO title="Ordine" keywords={[`vision care`]} description="Ordine" />
        {user.id > 0 && (
          <div className="container">
            <div className="row">
              <div className="col-12 pt-3">
                <h1 className={"page-title"}>Area Clienti</h1>
                <div className="row">
                  {/*  LEFT */}
                  <div className="col-12">
                    <UserAreaMenu
                      menuItemSelected="Ordine"
                      refresh={refreshSideCart}
                    />

                    {/* <SideCart refresh={refreshSideCart} />*/}
                  </div>

                  {/*  CENTER */}
                  <div className="col-12">
                    {/* TITLE */}
                    <h2 className={"customers-area-title"}>Nuovo ordine</h2>

                    {!storesLoading &&
                      ((stores !== "" && stores.length > 0) ||
                        !userIsClient) && (
                        <>
                          {/*  SELECT STORE */}
                          <label htmlFor="stores">
                            Seleziona un{" "}
                            {userIsClient ? "punto vendita" : "cliente"}
                          </label>

                          {userIsClient && !storesLoading && (
                            <ReactSelect
                              placeholder="cerca..."
                              id="stores"
                              options={stores}
                              value={storeSelected}
                              onChange={this.handleClientStoreSearchChange}
                            />
                          )}

                          {!userIsClient && (
                            <input
                              type="text"
                              className="form-control"
                              id="products"
                              placeholder="inizia a digitare il nome del cliente..."
                              onChange={this.handleStoreSearchChange}
                              value={storeSearchText}
                            />
                          )}

                          {!userIsClient && drawStoreCard(storeSelected)}
                        </>
                      )}

                    {!storesLoading &&
                      userIsClient &&
                      stores !== "" &&
                      stores.length === 0 &&
                      storeSearchText.length > 3 && (
                        <span className="text-danger">
                          Non hai alcun punto vendita associato a questo
                          account. Contatta il servizio clienti per maggiori
                          informazioni!
                        </span>
                      )}

                    {storesLoading && (
                      <div className="my-5 py-5">
                        <SpinnerLoading />
                      </div>
                    )}

                    {!storesLoading &&
                      ((stores && stores.length > 0) || !userIsClient) &&
                      storeSelected.id === 0 &&
                      storeSearchText.trim().length >= 3 && (
                        <div className="row">
                          <div className="col-12 d-flex flex-row-reverse">
                            <Pagination
                              pagination={paginationData}
                              onPageChange={this.handleOnStoresPageChange}
                            />
                          </div>
                          <div className="col-12">
                            <table>
                              <tbody>
                                {stores.map(store => (
                                  <tr
                                    key={store.id}
                                    className="c-pointer userAreaMenuItem"
                                    onClick={e =>
                                      this.handleSelectStoreByAgent(e, store)
                                    }
                                  >
                                    <td>{drawStoreLabel(store)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-12 d-flex flex-row-reverse">
                            <Pagination
                              pagination={paginationData}
                              onPageChange={this.handleOnStoresPageChange}
                            />
                          </div>
                        </div>
                      )}

                    {/* SELECT PRODUCT */}
                    {storeSelected.id > 0 && (
                      <div className="mt-3">
                        {!userIsClient && (
                          <label htmlFor="products">Ricerca un prodotto:</label>
                        )}
                        {!userIsClient && (
                          <input
                            type="text"
                            className="form-control"
                            id="products"
                            value={productSearch}
                            placeholder="inizia a digitare il nome od il codice del prodotto..."
                            onChange={this.handleProductSearchChange}
                          />
                        )}
                      </div>
                    )}

                    {((stores !== "" && stores.length > 0) || !userIsClient) &&
                      storeSelected.id > 0 &&
                      !productsLoading && (
                        <div className="mt-3 select-products-groups">
                          <label htmlFor="products">
                            Seleziona per gruppo di prodotti:{" "}
                          </label>

                          <div className="product-groups-wrapper">
                            {groupsParentArray.length > 0 && (
                              <button
                                className="level-up"
                                onClick={this.handleGroupLevelUp}
                                disabled={cartUploading === true}
                              >
                                <FaLevelUpAlt />
                              </button>
                            )}
                            <div className={"product-groups-inner"}>
                              {groupsToShow.length > 0 &&
                                groupsToShow.map(group => (
                                  <button
                                    disabled={cartUploading === true}
                                    className={`product-group ${Ordine.stringToCssClass(
                                      group.attributes.label
                                    )}`}
                                    key={group.id}
                                    onClick={e =>
                                      this.handleGroupClick(e, group)
                                    }
                                  >
                                    <span className="product-group-icon">
                                      {Ordine.provideProductGroupIcon(
                                        Ordine.stringToCssClass(
                                          group.attributes.label
                                        )
                                      )}
                                    </span>
                                    <div>
                                      <span className="product-group-label">
                                        {group.attributes.label}
                                      </span>
                                    </div>
                                  </button>
                                ))}
                              {productModelsToShow.length > 0 &&
                                productModelsToShow.map(productModel => (
                                  <button
                                    disabled={cartUploading === true}
                                    className={
                                      productModel.selected === true
                                        ? "product-group active"
                                        : "product-group"
                                    }
                                    key={productModel.id}
                                    onClick={e =>
                                      this.handleGroupClick(e, productModel)
                                    }
                                  >
                                    <span className="product-group-icon" />
                                    <div>
                                      <span className="product-group-label">
                                        {productModel.attributes.label
                                          ? productModel.attributes.label
                                          : productModel.attributes.code}
                                      </span>
                                    </div>
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      )}

                    {productsLoading && (
                      <div className="my-5 py-5">
                        <SpinnerLoading />
                      </div>
                    )}

                    {showToricSelects && !productsLoading && (
                      <div className="meta-fields">
                        <div className="mt-3">
                          <label htmlFor="spheres" className="ml-2 mt-0 mb-0">
                            Sfera
                          </label>
                          <ReactSelect
                            defaultValue={metaFilter.spheres}
                            placeholder="cerca..."
                            id="spheres"
                            options={spheres}
                            onChange={this.handleMetaChange}
                          />
                        </div>

                        <div className="mt-3">
                          <label htmlFor="cylinders" className="ml-2 mt-2 mb-0">
                            Cilindro
                          </label>
                          <ReactSelect
                            defaultValue={metaFilter.cylinders}
                            placeholder="cerca..."
                            id="cylinders"
                            options={cylinders}
                            onChange={this.handleMetaChange}
                          />
                        </div>

                        <div className="mt-3">
                          <label htmlFor="axes" className="ml-2 mt-2 mb-0">
                            Asse
                          </label>
                          <ReactSelect
                            defaultValue={metaFilter.axes}
                            placeholder="cerca..."
                            id="axes"
                            options={axes}
                            onChange={this.handleMetaChange}
                          />
                        </div>
                      </div>
                    )}

                    {!productsLoading &&
                      searchMode === "manual" &&
                      productsFiltered.length === 0 &&
                      storeSelected.id > 0 && (
                        <label className="mt-4 w-100 c grayLabel">
                          Nessun prodotto trovato...
                        </label>
                      )}

                    {!productsLoading &&
                      (productsFiltered.length > 0 && (
                        <div className="mt-5">
                          <div className="row align-items-center mb-3">
                            <div className="col-6">
                              <h5 className={"m-0"}>
                                <strong>Prodotti trovati:</strong>
                              </h5>
                            </div>

                            <div className="col-6 r">
                              {productFilteredSelected > 0 && (
                                <button
                                  disabled={cartUploading === true}
                                  className="btn btn-primary btn-sm"
                                  onClick={e =>
                                    this.handleAddSelectedProductsToCart(e)
                                  }
                                >
                                  + Aggiungi{" "}
                                  {productFilteredSelected > 0
                                    ? productFilteredSelected + " prodotti "
                                    : ""}{" "}
                                  all'ordine
                                </button>
                              )}
                            </div>
                          </div>

                          {productsFiltered.length > 0 && (
                            <div
                              className="responsive-table-wrapper"
                              id="productsFilteredAnchor"
                            >
                              <div className="w-100 d-flex flex-row-reverse">
                                <Pagination
                                  pagination={paginationProducts}
                                  onPageChange={this.handleOnProductPageChange}
                                />
                              </div>

                              <table className="products-table">
                                <thead>
                                  <tr>
                                    <th>Descrizione</th>
                                    <th className="c">Quantità</th>
                                    {!userIsClient && (
                                      <th className="c">Sconto merce</th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {productsFiltered.map(product => (
                                    <tr key={product.id}>
                                      <td
                                        className={
                                          "products-table-description-cell"
                                        }
                                      >
                                        {product.attributes.description}
                                      </td>

                                      <td className="c products-table-quantity">
                                        <div className={"quantity-spinner"}>
                                          <button
                                            disabled={cartUploading}
                                            className="btn btn-secondary products-table-quantity-remove"
                                            onClick={e =>
                                              this.handlePlusMinusClick(
                                                e,
                                                product,
                                                "quantity",
                                                -1
                                              )
                                            }
                                          >
                                            -
                                          </button>

                                          <input
                                            id="quantity"
                                            placeholder="0"
                                            className="form-control products-table-quantity-input"
                                            type="number"
                                            value={product.quantity}
                                            onChange={e =>
                                              this.handleInputChange(product, e)
                                            }
                                          />

                                          <button
                                            disabled={cartUploading}
                                            className="btn btn-secondary products-table-quantity-add"
                                            onClick={e =>
                                              this.handlePlusMinusClick(
                                                e,
                                                product,
                                                "quantity",
                                                1
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      </td>
                                      {!userIsClient && (
                                        <td className="c products-table-quantity">
                                          {!product.meta.is_free_sample && (
                                            <div className={"quantity-spinner"}>
                                              <button
                                                disabled={cartUploading}
                                                className="btn btn-secondary products-table-quantity-remove"
                                                onClick={e =>
                                                  this.handlePlusMinusClick(
                                                    e,
                                                    product,
                                                    "discountedQuantity",
                                                    -1
                                                  )
                                                }
                                              >
                                                -
                                              </button>

                                              <input
                                                id="discountedQuantity"
                                                placeholder="0"
                                                disabled={cartUploading}
                                                className="form-control products-table-quantity-input"
                                                type="number"
                                                value={
                                                  product.discountedQuantity
                                                }
                                                onChange={e =>
                                                  this.handleInputChange(
                                                    product,
                                                    e
                                                  )
                                                }
                                              />

                                              <button
                                                disabled={cartUploading}
                                                className="btn btn-secondary products-table-quantity-add"
                                                onClick={e =>
                                                  this.handlePlusMinusClick(
                                                    e,
                                                    product,
                                                    "discountedQuantity",
                                                    1
                                                  )
                                                }
                                              >
                                                +
                                              </button>
                                            </div>
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              <div className="w-100 d-flex flex-row-reverse">
                                <Pagination
                                  pagination={paginationProducts}
                                  onPageChange={this.handleOnProductPageChange}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

export default Ordine
